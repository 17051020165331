<template>
  <header class="header">
   
    <div class="nav-container">
      <router-link :to="{ name: 'home' }" @mouseenter="closeDropdown">
        <img class="logo" src="../../assets/invrs-logo-white-full.png" />
      </router-link>
      <nav>
        <ul>
          <li class="nav-link-container" @mouseenter="mouseEnterYourJourneyHeader">
            <router-link
              class="your-journey"
              :to="{ name: 'home' }"
              @click="mouseClickYourJourneyHeader"
              >Your Journey</router-link
            >
            <img
              src="../../assets/chevron-down-outline.svg"
              :class="{
                'nav-arrow-flipped': yourJourneyDropdown,
              }"
            />
          </li>
          <li>
            <router-link :to="{ name: 'about-us' }" @mouseenter="closeDropdown"
              >About Us</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'plans' }" @mouseenter="closeDropdown"
              >Pricing</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'feed' }" @mouseenter="closeDropdown"
              >Latest Posts</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'fintrivia' }" @mouseenter="closeDropdown"
              >FinTrivia</router-link
            >
          </li>
        </ul>
      </nav>

      <nav>
        <ul>
          <li>
            <a href="#" @mouseenter="closeDropdown" @click="goToLogin">Login</a>
          </li>
          <li>
            <a
              class="cta btn"
              href="#"
              @mouseenter="closeDropdown"
              @click="goToSignup"
              >Sign Up</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <transition>
    <your-journey-dropdown
      v-show="yourJourneyDropdown"
      class="dropdown"
      @mouseleave="mouseLeaveYourJourneyDrop"
      @home-option-click="optionClickHome"
    ></your-journey-dropdown>
  </transition>
</template>

<script>
import YourJourneyDropdown from "../nav/YourJourneyDropdown.vue";
import { SIGNUP_URL, LOGIN_URL } from "../../config.js";

export default {
  components: {
    "your-journey-dropdown": YourJourneyDropdown,
  },

  data() {
    return {
      mouseInYourJourneyHeader: false,
      mouseInYourJourneyDrop: false
    };
  },

  computed: {
    yourJourneyDropdown() {
      return this.mouseInYourJourneyHeader && this.mouseInYourJourneyDrop;
    },
  },

  methods: {
    mouseEnterYourJourneyHeader() {
      this.mouseInYourJourneyHeader = true;
      this.mouseInYourJourneyDrop = true;
    },
    mouseLeaveYourJourneyHeader() {
      this.mouseInYourJourneyHeader = false;
    },
    mouseClickYourJourneyHeader() {
      this.mouseInYourJourneyHeader = false;
      this.mouseInYourJourneyDrop = false;
    },
    mouseLeaveYourJourneyDrop() {
      this.mouseInYourJourneyDrop = false;
    },
    closeDropdown() {
      this.mouseInYourJourneyHeader = false;
      this.mouseInYourJourneyDrop = false;
    },
    optionClickHome(id) {
      this.mouseInYourJourneyHeader = false;
      this.mouseInYourJourneyDrop = false;

      this.$router.push({ name: "home" }).then(() => {
        const section = document.querySelector(id);
        section.scrollIntoView({ behavior: "smooth" });
      });
    },
    goToSignup() {
      window.location.href = SIGNUP_URL;
    },
    goToLogin() {
      window.location.href = LOGIN_URL;
    },
  },
};
</script>

<style scoped>
.header {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 1);
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8rem;
  padding: 0 3.2rem;
}

.banner {
  background-color: #693bf5; 
  padding: 1rem 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-text, .banner-text a, .banner-text a:active, .banner-text a:visited {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  line-height: 1.2;
}

.banner-text a {
  text-decoration: underline;
}

.banner-text a:hover, .banner-text a:focus {
  color: #3c9;
}

nav {
  height: 100%;
}

.dropdown {
  margin-top: 80px;
  position: fixed;
  z-index: 998;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-active {
  transition: opacity 0.2s ease-in;
  -webkit-transition: opacity 0.2s ease-in;
}

.v-leave-from,
.v-enter-to {
  opacity: 1;
}

.v-leave-active {
  transition: opacity 0.2s ease-out;
  -webkit-transition: opacity 0.2s ease-out;
}

.logo {
  height: 4.4rem;
}

ul {
  list-style: none;
  display: flex;
  align-items: center;
  height: 100%;
}

li a {
  margin-right: 0.4rem;
}

.nav-link-container {
  display: flex;
  align-items: center;
}

.nav-arrow-flipped {
  transform: rotate(-180deg);
}

li {
  margin-right: 4.8rem;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/** about 1040px */
@media (max-width: 67em) { 
  li {
    margin-right: 2.8rem;
  }
}

li:last-child {
  margin-right: 0;
}

li img {
  height: 2rem;
  color: #333;
  transition: all 0.2s ease-in;
}

a {
  text-decoration: none;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 500;
  color: inherit;
}

.your-journey {
  height: 100%;
  display: flex;
  align-items: center;
}

.cta {
  padding: 1.2rem 2.4rem;
  border-radius: 8px;
  font-weight: 500;
  color: #fff;
  background-image: linear-gradient(
    to right,
    #693bf5,
    rgb(105, 59, 245, 0.85),
    rgb(105, 59, 245, 0.7)
  );
}

.cta:hover {
  transform: scale(1.05);
  color: #fff;
}

.cta:active {
  transform: scale(0.95);
  color: #fff;
}

a:hover,
a:active,
a.active {
  color: #333;
}
</style>
